<template>
  <div>
    <b-card>
      <b-overlay
        :show="!payments"
        rounded="sm"
      >
        <div class="invoice-table">
          <!-- search input -->
          <div class="custom-search d-flex justify-content-end">
            <b-form-group>
              <div class="d-flex align-items-center">
                <label class="mr-1">Search</label>
                <b-form-input
                  v-model="searchTerm"
                  placeholder="Search"
                  type="text"
                  class="d-inline-block"
                />
              </div>
            </b-form-group>
          </div>

          <!-- table -->
          <vue-good-table
            v-if="payments"
            :columns="columns"
            :rows="rows"
            :rtl="direction"
            :search-options="{
              enabled: true,
              externalQuery: searchTerm }"
            :pagination-options="{
              enabled: true,
              perPage:serverParams.perPage
            }"
            :total-rows="totalRecords"
            :is-loading.sync="isLoading"
            mode="remote"
            @on-page-change="onPageChange"
            @on-sort-change="onSortChange"
            @on-column-filter="onColumnFilter"
            @on-per-page-change="onPerPageChange"
            @on-search="onSearch"
          >
            <template slot="loadingContent">
              <div
                class="my-1 d-flex spinner-border text-primary justify-content-center"
                style="width: 2.5rem; height: 2.5rem; margin: auto;"
                role="status"
              />
            </template>
            <template
              slot="table-row"
              slot-scope="props"
            >

              <!-- Column: Invoice No -->
              <span v-if="props.column.field === 'concat_domain'">
                <router-link
                  :to="`/user/details/${props.row.uid}`"
                >
                  {{ props.row.concat_domain != null ? props.row.concat_domain : 'N/A' }}
                </router-link>
              </span>

              <!-- Column: Payment -->
              <span v-else-if="props.column.field === 'payment_value'">
                {{ formatPrice(props.row) }}
              </span>

              <!-- Column: Date Paid -->
              <span v-else-if="props.column.field === 'payment_timestamp'">
                {{ formatDate(props.row.payment_timestamp) }}
              </span>

              <!-- Column: Status -->
              <span v-else-if="props.column.field === 'result'">
                <b-badge :variant="statusVariant(getStatus(props.row.result))">
                  {{ getStatus(props.row.result) }}
                </b-badge>
              </span>

              <!-- Column: Common -->
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>

            <!-- pagination -->
            <template
              slot="pagination-bottom"
              slot-scope="props"
            >
              <div class="d-flex justify-content-between flex-wrap mt-1">
                <div class="d-flex align-items-center mb-0 mt-1">
                  <span class="text-nowrap">
                    Showing 1 to
                  </span>
                  <b-form-select
                    v-model="serverParams.perPage"
                    :options="['50','100','150']"
                    class="mx-1"
                    @input="(value)=>props.perPageChanged({currentPerPage:value})"
                  />
                  <span class="text-nowrap"> of {{ props.total }} entries </span>
                </div>
                <div>
                  <b-pagination
                    :value="1"
                    :total-rows="props.total"
                    :per-page="serverParams.perPage"
                    first-number
                    last-number
                    align="right"
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mt-1 mb-0"
                    @input="(value)=>props.pageChanged({currentPage:value})"
                  >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>
                </div>
              </div>
            </template>
          </vue-good-table>
        </div>
      </b-overlay>
    </b-card><br>
    <!-- BALANCE PAYMENTS TABLE -->
    <b-card v-if="rows2.length > 0">
      <div class="invoice-table">
        <div class="d-flex justify-content-between align-items-center">
          <h3 class="mb-2">
            Balance Payments
          </h3>
        </div>
        <vue-good-table
          v-if="rows2.length > 0"
          :columns="columns"
          :rows="rows2"
          :rtl="direction"
          :pagination-options="{
            enabled: true,
            perPage:serverParams2.perPage
          }"
          :total-rows="totalRecords2"
          :is-loading.sync="isLoading2"
          mode="remote"
          @on-page-change="onPageChange2"
          @on-sort-change="onSortChange2"
          @on-column-filter="onColumnFilter2"
          @on-per-page-change="onPerPageChange2"
          @on-search="onSearch2"
        >
          <template slot="loadingContent">
            <div
              class="my-1 d-flex spinner-border text-primary justify-content-center"
              style="width: 2.5rem; height: 2.5rem; margin: auto;"
              role="status"
            />
          </template>
          <template
            slot="table-row"
            slot-scope="props"
          >

            <!-- Column: Invoice No -->
            <span v-if="props.column.field === 'concat_domain'">
              <router-link
                :to="`/user/details/${props.row.uid}`"
              >
                {{ props.row.concat_domain != null ? props.row.concat_domain : 'N/A' }}
              </router-link>
            </span>

            <!-- Column: Payment -->
            <span v-else-if="props.column.field === 'payment_value'">
              {{ formatPrice(props.row) }}
            </span>

            <!-- Column: Date Paid -->
            <span v-else-if="props.column.field === 'payment_timestamp'">
              {{ formatDate(props.row.payment_timestamp) }}
            </span>

            <!-- Column: Status -->
            <span v-else-if="props.column.field === 'result'">
              <b-badge :variant="statusVariant(getStatus(props.row.result))">
                {{ getStatus(props.row.result) }}
              </b-badge>
            </span>

            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- pagination -->
          <template
            slot="pagination-bottom"
            slot-scope="props"
          >
            <div class="d-flex justify-content-between flex-wrap mt-1">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap ">
                  Showing 1 to
                </span>
                <b-form-select
                  v-model="serverParams2.perPage"
                  :options="['50','100','150']"
                  class="mx-1"
                  @input="(value)=>props.perPageChanged({currentPerPage:value})"
                />
                <span class="text-nowrap"> of {{ props.total }} entries </span>
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="serverParams2.perPage"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="(value)=>props.pageChanged({currentPage:value})"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BPagination, BFormGroup, BFormInput, BFormSelect, BCard, BOverlay, BBadge,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import moment from 'moment'

export default {
  components: {
    VueGoodTable,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BOverlay,
    BCard,
    BBadge,
  },
  data() {
    return {
      pageLength: 50,
      dir: false,
      columns: [
        {
          label: 'Domain',
          field: 'concat_domain',
          sortable: true,
        },
        {
          label: 'Amount',
          field: 'payment_value',
          sortable: true,
        },
        {
          label: 'Date',
          field: 'payment_timestamp',
          sortable: true,
        },
        {
          label: 'Method',
          field: 'paidwith_text',
          sortable: true,
        },
        {
          label: 'Status',
          field: 'result',
        },
      ],
      rows: [],
      rows2: [],
      searchTerm: '',
      done: false,
      isLoading: false,
      totalRecords: 0,
      serverParams: {
        search: '',
        columnFilters: {},
        sort: [
          {
            field: 'payments.id',
            type: 'desc',
          },
        ],
        page: 1,
        perPage: 50,
      },
      searchTerm2: '',
      done2: false,
      isLoading2: false,
      totalRecords2: 0,
      serverParams2: {
        search: '',
        columnFilters: {},
        sort: [
          {
            field: 'payments.id',
            type: 'desc',
          },
        ],
        page: 1,
        perPage: 50,
      },
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Canceled      : 'light-warning',
        Paid : 'light-success',
        Failed     : 'light-danger',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    payments() {
      return this.$store.state.invoice.paymentRegister
    },
  },
  created() {
    // this.fetchPaymentRegister()
    this.loadItems()
    this.loadItems2()
  },
  mounted() {
  },
  methods: {
    formatDate(Date) {
      return moment(Date).format('DD MMM YYYY')
    },
    getStatus(txt) {
      if (txt === 'canceled') return 'Canceled'
      if (txt === 'paid') return 'Paid'
      return 'Failed'
    },

    formatPrice(item) {
      // console.log(item.totalValue == null)
      if (item.payment_value == null) return `${item.currency} 0.00`
      if (item.invoice_type === 'custom') return `${item.currency} ${parseFloat(item.payment_value).toFixed(2)}`
      const amount = parseFloat(item.payment_value).toFixed(2)
      return `€ ${amount}`
    },

    // vue good table
    updateParams(newProps) {
      this.serverParams = { ...this.serverParams, ...newProps }
    },
    onPageChange(params) {
      this.updateParams({ page: params.currentPage })
      this.loadItems()
    },
    onPerPageChange(params) {
      this.updateParams({ page: 1, perPage: params.currentPerPage })
      this.loadItems()
    },
    onSortChange(params) {
      this.updateParams({
        sort: params,
      })
      this.loadItems()
    },
    onColumnFilter(params) {
      this.updateParams(params)
      this.loadItems()
    },
    onSearch() {
      this.updateParams({ page: 1, perPage: this.serverParams.perPage, search: this.searchTerm })
      this.loadItems()
    },
    // load items is what brings back the rows from server
    loadItems() {
      this.$store.dispatch('invoice/fetchPaymentRegisterSSR', this.serverParams)
        .then(response => {
          this.totalRecords = response.total
          this.rows = this.$store.state.invoice.paymentRegister
          this.isLoading = false
          this.done = true
        })
        .catch(error => {
          console.log(error)
        })
    },

    // vue good table
    updateParams2(newProps) {
      this.serverParams2 = { ...this.serverParams2, ...newProps }
    },
    onPageChange2(params) {
      this.updateParams2({ page: params.currentPage })
      this.loadItems2()
    },
    onPerPageChange2(params) {
      this.updateParams2({ page: 1, perPage: params.currentPerPage })
      this.loadItems2()
    },
    onSortChange2(params) {
      this.updateParams2({
        sort: params,
      })
      this.loadItems2()
    },
    onColumnFilter2(params) {
      this.updateParams2(params)
      this.loadItems2()
    },
    onSearch2() {
      this.updateParams2({ page: 1, perPage: this.serverParams2.perPage, search: this.searchTerm })
      this.loadItems()
    },
    // load items is what brings back the rows from server
    loadItems2() {
      this.$store.dispatch('invoice/fetchPaymentRegisterSSR2', this.serverParams2)
        .then(response => {
          this.totalRecords2 = response.total
          this.rows2 = this.$store.state.invoice.paymentRegister2
          this.isLoading2 = false
          this.done2 = true
        })
        .catch(error => {
          console.log(error)
        })
    },
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';

.invoice-table {
  thead tr{
    background: #f3f2f7;
  }

  th, td {
    font-size: 14px;
    text-align: center !important;
    vertical-align: middle !important;
  }
}
</style>

<style lang="scss" scoped>
.dark-layout a {
  color: #fb50a1 !important;
}
</style>
